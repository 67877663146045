import { useErrorThrower } from "components/ErrorContext";
import Button from "components/shared/button";
import React, { useState } from "react";
import baseUrl from "../../api/kundesystem/config";
import ValueCheckbox from "../shared/valueCheckbox";
import { StatusTypeEnum } from "components/shared/status";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default function ContactForm(props) {
  const [formState, setFormState] = useState({});
  const throwGlobalError = useErrorThrower();

  const handleChange = e => {
    setFormState(prevValue => {
      prevValue[e.target.name] = e.target.value;
      return prevValue;
    });
  };

  const handleArrayChange = e => {
    let v = formState[e.target.name];
    if (v instanceof Array) {
      v.push(e.target.value);
    } else {
      v = [e.target.value];
    }
    setFormState(prevValue => {
      prevValue[e.target.name] = v;
      return prevValue;
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    fetch(baseUrl + "/relations/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formState,
      }),
    })
      .then(res => {
        //fetch ( and its polyfills ) only rejects on network failure. We have to check for 400 or 500 errors ourselves
        if (res.ok) {
          props.setOpenForm(false);
          props.setStatus({
            type: StatusTypeEnum.SUCCESS,
            message: "Takk for henvendelsen",
          });
        } else {
          //something went wrong
          props.setStatus({
            type: StatusTypeEnum.ERROR,
            message: "Noe gikk galt : " + res.status + " - " + res.statusText,
          });
        }
      })
      .catch(error => {
        throwGlobalError(error);
        props.setStatus({
          type: StatusTypeEnum.ERROR,
          message: "Dette gikk galt:" + error,
        });
      });
  };

  const checkBoxFields = [
    "Anleggsbidrag",
    "Arbeid under spenning",
    "Beredskap",
    "Byggherreansvar, SHA-plan og risikovurdering",
    "Distribuert produksjon og plusskunder",
    "Drifts- og anleggsmerking",
    "Elmåling",
    "Fellesføring, Ekom",
    "Inntak og tilknytningspunkt",
    "Installasjon",
    "Internkontroll",
    "Jording og overspenningsvern",
    "Kabelnett",
    "Luftnett",
    "Nettstasjoner",
    "Rettigheter og grunneieravtaler",
    "Spesifikasjoner",
    "Stasjonsanlegg og regionalnett",
    "Vedlikehold",
    "Veilys og utendørsbelysning",
  ];
  const tools = [
    "AUS-mentor",
    "Anleggsbidrag",
    "Beredskapsdatabase",
    "Brukerguider - FEF, FSE og FEK",
    "Grøft",
    "Kostnadskatalog med kalkyle",
    "Kostnadsrapportering",
    "LS-mast",
    "Ledning",
    "Netlin",
    "Støy",
    "Planbok",
    "Risikovurdering",
    "Skjema og sjekklister",
    "Stikkprøveadministrasjon",
    "Veilys",
  ];

  return (
    <>
      <section className="o-wrapper--narrow u-bg--light-yellow c-form-block">
        <form
          name="subscription"
          method="POST"
          className="u-padding-sides u-padding-top-bottom"
          onSubmit={handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="subscription" />
          <label htmlFor="email" className="c-form--bold-text">
            Vi trenger din e-postadresse
          </label>
          <input
            onChange={handleChange}
            id="email"
            name="email"
            type="email"
            required
          />
          <label htmlFor="name">Ditt navn</label>
          <input onChange={handleChange} id="name" name="name" type="text" />
          <p>
            Alt nedenfor er valgfritt å fylle inn, men det hjelper oss å tilby
            deg riktig abonnement:
          </p>

          <label htmlFor="companyname">Selskapsnavn</label>
          <input
            onChange={handleChange}
            id="companyname"
            name="company-name"
            type="text"
          />
          <label htmlFor="orgnumber">Organisasjonsnummer</label>
          <input
            onChange={handleChange}
            id="orgnumber"
            name="orgnumber"
            type="text"
          />
          <label htmlFor="companytype">Hvilken type selskap jobber du i?</label>
          <input
            onChange={handleChange}
            id="companytype"
            name="companytype"
            type="text"
          />

          <label htmlFor="message" className="c-form--bold-text">
            Hva trenger du tilgang til?
            <br />
          </label>
          <textarea
            onChange={handleChange}
            id="message"
            name="message"
            className="c-form__message"
            //type="text"
          />

          <div className="c-form__submitfield">
            <Button text="Send inn" />
            <p className="u-fontSmall">
              Vi tar kontakt med deg på e-postadressen du har oppgitt
            </p>
          </div>

          <p className="c-form--bold-text">Hvilke fag jobber du med?</p>
          {checkBoxFields.map(checkBoxField => (
            <ValueCheckbox
              type="checkbox"
              id="type[]"
              key={checkBoxField}
              value={checkBoxField}
              label={checkBoxField}
              onChange={handleArrayChange}
            />
          ))}

          <p className="c-form--bold-text">
            Verktøy og tjenester du ønsker tilgang til
          </p>
          {tools.map(tool => (
            <ValueCheckbox
              type="checkbox"
              id="tool[]"
              key={tool}
              label={tool}
              value={tool}
              onChange={handleArrayChange}
            />
          ))}

          <div className="c-form__submitfield">
            <Button type="submit" variant="super">
              Send inn
            </Button>
            <p className="u-fontSmall">
              Vi tar kontakt med deg på e-postadressen du har oppgitt
            </p>
          </div>
        </form>
      </section>
    </>
  );
}

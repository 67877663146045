import React from "react";

const ValueCheckbox = ({
  id,
  label,
  size = "normal",
  className,
  checked,
  onChange,
  value,
  disabled = false,
}) => {
  return (
    <label className={`checkbox checkbox-${size} ${className}`} key={id}>
      <input
        type="checkbox"
        name={id}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="checkmark" />
      {label}
    </label>
  );
};

export default ValueCheckbox;

import CardMinor from "./card.minor";
import PropTypes from "prop-types";

const CardListMinorScroll = ({ entries, index }) => (
  <div className="o-card-listing o-card-listing--scroll">
    {entries.map(entry => (
      <CardMinor entry={entry} key={entry.title} index={index} />
    ))}
  </div>
);

CardListMinorScroll.propTypes = {
  entries: PropTypes.array,
  index: PropTypes.number,
};

export default CardListMinorScroll;

import React, { DetailedHTMLProps, ButtonHTMLAttributes } from "react";
import { IconType } from "react-icons/lib";

const styleMapping = {
  super: "c-login-button",
  primaryLouminous: "c-button-primary-louminous",
  primaryGreen: "c-button-primary-green",
  primaryMagenta: "c-button-primary-magenta",
  secondaryGreen: "c-button-secondary-green",
  secondaryLouminous: "c-button-secondary-louminous",
  secondaryMagenta: "c-button-secondary-magenta",
  warningRedDanger: "c-button-warning-red-danger",
  themed: "c-button-themed",
  simple: "c-button-simple",
  editorNavbar: "c-rich-navbar-button",
};

type Props = {
  variant: keyof typeof styleMapping;
  icon?: IconType;
  iconTitle?: string;
  childen?: React.ReactNode;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      variant,
      icon: Icon,
      iconTitle = "",
      className: appendedClasses,
      ...rest
    },
    buttonRef
  ) => {
    const cssComponent = styleMapping[variant];
    return (
      <button
        ref={buttonRef}
        className={mix(cssComponent, appendedClasses)}
        {...rest}
      >
        {Icon ? (
          <>
            <Icon className={`${cssComponent}__icon`} title={iconTitle} />
            {children && (
              <span className={`${cssComponent}__text`}>{children}</span>
            )}
          </>
        ) : (
          children
        )}
      </button>
    );
  }
);
Button.displayName = "SharedButton"; // This is just for debugging purposes

function mix(...classesToMix: string[]): string {
  return classesToMix.filter(c => c).join(" ");
}

export default Button;
